<template>
  <div class="page flex-col">
    <div class="outer1 flex-col">
      <div class="group1 flex-row">
        <img class="img1" referrerpolicy="no-referrer" src="./assets/img/psxqkrx37h35f6zbsmdilckq0kfuuwll08eac31c7a-8003-4f47-9c64-4f6e5bf8ce68.png" />
      </div>
      <div class="group2 flex-row">
        <div class="box1 flex-col">
          <div class="outer2 flex-col">
            <div class="section1 flex-col"></div>
            <span class="info1">申请试用</span>
            <img class="pic1" referrerpolicy="no-referrer" src="./assets/img/ps4t011txk4369tlb8w7wfj5ozpmq07sdf8a2fde7b-2eea-428f-9172-89c9d0003b29.png" />
            <span class="word1">请留下联系方式，我们会尽快联系您为您生成使用账号。</span>
            <span class="info2">咨询热线：18611685988</span>
            <div class="form">
              <el-form label-position="right" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="姓名" prop="name">
                  <el-input style="width: 370px; height: 40px" v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="手机" prop="phone">
                  <el-input style="width: 370px; height: 40px" v-model="ruleForm.phone"></el-input>
                </el-form-item>
                <el-form-item label="验证码" prop="authCode" class="auth-code">
                  <el-input style="width: 204px" v-model="ruleForm.authCode"></el-input>
                  <el-button style="width: 154px; height: 40px; margin-left: 12px" type="primary" :disabled="disabled" @click="authCode()">
                    <span v-if="time != 60">{{ time }} </span>
                    {{ authText }}</el-button
                  >
                </el-form-item>
                <el-form-item label="公司名称" prop="corporateName">
                  <el-input style="width: 370px; height: 40px" v-model="ruleForm.corporateName"></el-input>
                </el-form-item>
                <el-form-item label="需求描述" prop="demand">
                  <el-input style="width: 370px; height: 40px" v-model="ruleForm.demand"></el-input>
                </el-form-item>
                <el-button style="width: 100%; height: 40px" type="primary" @click="submitForm('ruleForm')">立即创建 </el-button>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios"
const url = "https://lims.jcbtest.com/"
export default {
  data() {
    return {
      time: 60,
      authText: "验证码",
      disabled: false,
      ruleForm: {},
      rules: {
        name: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { min: 11, max: 11, message: "请输入正确的手机号", trigger: "blur" },
        ],
        authCode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          { min: 6, max: 6, message: "请输入正确的验证码", trigger: "blur" },
        ],
        corporateName: [{ required: true, message: "请输入公司名称", trigger: "blur" }],
      },
    }
  },
  methods: {
    submitForm(formName) {
      let data = {
        mobile: this.ruleForm.phone,
        truename: this.ruleForm.name,
        company: this.ruleForm.corporateName,
        memo: this.ruleForm.demand,
        vcode: this.ruleForm.authCode,
        from:'lims官网'
      }
      this.$refs[formName].validate(valid => {
        if (valid) {
          axios
            .post(url + "v3/site/add-apply-record", data)
            .then(res => {
              if (res.data.code == 200) {
                this.$message.success(res.data.message)
                this.$alert(res.data.message, "提示", {
                  confirmButtonText: "确定",
                })
                this.ruleForm = {}
              } else {
                this.$message.error(res.data)
              }
            })
            .catch(err => {
              console.log(err)
              this.$message.error("提交失败")
            })
        } else {
          console.log("error submit!!")
          return false
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    authCode() {
      if (!this.ruleForm.phone) {
        this.$message.error("请输入手机号码")
        return
      }
      if (this.ruleForm.phone.length != 11) {
        this.$message.error("请输入正确手机号码")
        return
      }
      this.disabled = true
      let mobile = this.ruleForm.phone
      axios
        .post(url + "v3/site/send-sms-vcode", { mobile })
        .then(res => {
          console.log(res)
          this.$notify({
            title: "成功",
            message: "验证码发送成功",
            type: "success",
          })
        })
        .catch(err => {
          console.log(err)
        })

      this.timer = setInterval(() => {
        this.time -= 1
        this.authText = "秒后可重发"
        if (this.time == 0) {
          clearInterval(this.timer)
          this.authText = "验证码"
          this.time = 60
          this.disabled = false
        }
      }, 1000)
    },
  },
}
</script>

<style scoped lang="css" src="./assets/index.css" />
<style scoped>
.outer1 {
  width: 1920px;
  margin: auto;
}

.form {
  width: 480px;
  margin: 20px auto;
}

.auth-code {
  width: 100%;
  display: inline-block;
}

/* .el-form-item {
  margin-bottom: 10px !important;
} */
</style>
