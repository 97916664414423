<template>
  <div id="app">
    <router-view />
    <footer-view/>
  </div>
</template>

<style lang="css" src="./assets/common.css"></style>

<style >
#app{
 width: 100%;
 text-align: center;
 overflow: hidden;
}
</style>
<script>
import footerView from "./components/footer.vue"
export default {
  components: {
    footerView
  },
  methods: {},
}
</script>
