<template>
  <div class="footer">
    <div class="footer-content">
      <div class="link">
        <span>友情链接:</span>
        <span v-for="(item, index) in linkList" :key="index">
          <a :href="item.url" target="_blank">{{ item.name }}</a>
        </span>
      </div>
      <div class="copyright">
        <span class="copyright-text"> 版权所有:检策帮（北京）科技有限公司 版权所有</span>
        <a target="_blank" href="https://beian.miit.gov.cn/#/Integrated/index">京ICP备2021031723号</a>
      </div>
    </div>
  </div>
</template>

<script>
const url = "https://www.jcbtest.com/#/"
export default {
  data() {
    return {
      linkList: [
        {
          name: "检策帮",
          url: `${url}home`,
        },
        {
          name: "找检测",
          url: `${url}serve/home`,
        },
        {
          name: "仪器耗材",
          url: `${url}inst/home`,
        },
        {
          name: "实验室转让",
          url: `${url}labor/home`,
        },
        {
          name: "招投标查询",
          url: `${url}bidding/home`,
        },
        {
          name: "标准查询",
          url: `${url}standard/home`,
        },
        {
          name: "能力咨询",
          url: `${url}ability/consult`,
        },
        {
          name: "检测头条",
          url: `${url}news/home`,
        },
        {
          name: "检测人才",
          url: `${url}human/home`,
        },
      ],
    }
  },
}
</script>

<style scoped>
a {
  color: #ffffff;
  opacity: 0.6;
  font-size: 16px;
  margin-left: 50px;
}
.footer {
  margin: auto;
  width: 1920px;
  height: 150px;
  background: #203c5d;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer-content {
  border-top: 1px solid #41648d;
  padding-top: 40px;
  width: 1380px;
  height: 100px;
  color: #ffffff;
  opacity: 0.6;
  font-size: 16px;
}

.copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.copyright-text {
  margin-right: 50px;
}
</style>
