<template>
  <div class="page flex-col">
    <div class="banner-bg">
      <div class="hader">
        <img class="logo" src="../../assets/img/limslogo.png" />
        <div class="menu">
          <a class="menu-index" href="#">首页</a>
          <a class="menu-jcb" target="_blank" href="https://www.jcbtest.com/#/home">检策帮</a>
          <el-button class="menu-register" @click="openRegisterPage()">免费试用</el-button>
          <el-button type="primary" class="login" @click="login()">登录</el-button>
        </div>
      </div>
      <div class="banner-content">
        <div class="logo-text"><span>检策帮</span> <span class="LIMS">LIMS</span></div>
        <div class="text-top">人机料法环全链路管理, 数据赋能，助力企业降本增效</div>
        <div class="desc">检策帮 LIMS 运用现代数字技术与实验室管理体系密切融合，助力实验室接入数字生态，帮助实验室提升数字化能力，实现成本、效率的优化、构建核心竞争优势。</div>
        <el-button type="primary" class="register" @click="openRegisterPage()">免费试用</el-button>
      </div>
    </div>
    <img src="../../assets/img/1.jpg" class="bg-img" />
    <img src="../../assets/img/2.jpg" />
    <div class="resource-management">
      <el-button type="primary" class="register-two" @click="openRegisterPage()">免费试用</el-button>
    </div>
    <img src="../../assets/img/4.jpg" />
    <img class="last-img" src="../../assets/img/5.jpg" @click="openRegisterPage()" />
    <div class="contact">
      <div class="contact-text">•联系我们•</div>
      <div class="contact-content">
        <div class="content-left">
          <img class="phone-img" src="../../assets/img/形状1.png" />
          <span> 18611685988 </span>
        </div>
        <div class="content-right">
          <img class="qr-code" src="../../assets/img/二维码.png" />
          <img src="../../assets/img/二维码2.png" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      constants: {},
    }
  },
  methods: {
    openRegisterPage() {
      let routeData = this.$router.resolve({ path: "/register" })
      window.open(routeData.href, "_blank")
    },
    login() {
      window.open('https://lims.jcbtest.com/#/login', "_blank")
    },
  },
}
</script>
<style scoped lang="css" src="./assets/index.css" />
<style scoped>
.banner-bg {
  width: 100%;
  height: 660px;
  background-image: url("../../assets/img/banner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position:center;
}
.hader {
  margin: 40px auto;
  width: 1380px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.logo {
  height: 60px;
}

.contact {
  width: 100%;
  background: #203c5d;
}
.contact-text {
  font-size: 40px;
  font-weight: 700;
  color: #fff;
  margin-top: 20px;
  width: 100%;
  text-align: center;
}
.contact-content {
  width: 1380px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 50px auto;
}
.content-left {
  width: 514px;
  height: 230px;
  border: 1px solid #fff;
  border-radius: 5px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.phone-img {
  margin-right: 50px;
}
.content-right img {
  width: 230px;
  height: 230px;
}
.qr-code {
  margin-right: 50px;
}
.menu {
  font-size: 18px;
  display: flex;
  align-items: center;
}
.menu-index {
  color: #70c4ff;
}
.menu-jcb {
  color: #fff;
  margin: 0 60px;
  cursor: pointer;
}
.menu-register {
  border: 0;
  width: 120px;
  height: 40px;
  color: #000000;
  background: linear-gradient(to left, #ffeb93, #ffc831);
}
.banner-content {
  width: 1380px;
  margin: auto;
  display: flex;
  flex-direction: column;
  color: #fff;
  text-align: left;
}
.logo-text {
  font-size: 58px;
  margin-bottom: 30px;
}
.LIMS {
  color: #fb621e;
}
.text-top {
  margin-bottom: 30px;
  font-size: 24px;
}
.desc {
  font-size: 22px;
  margin-top: 10px;
  max-width:600px;
  line-height:40px;
}
.register {
  width: 122px;
  height: 48px;
  margin-top: 20px;
}
.resource-management {
  background-image: url(../../assets/img/3.jpg);
  height: 620px;
  width: 100%;
  position: relative;
  background-size: cover;
  background-position:center;
}
.register-two {
  position: absolute;
  width: 122px;
  height: 48px;
  left: 260px;
  top: 500px;
}
.last-img {
  cursor: pointer;
}
.login {
  border: 1px solid #ffab48;
  background: rgba(0, 0, 0, 0);
  color: #ffab48;
  width: 80px;
  height: 40px;
  margin-left: 50px;
}
.bg-img{
    width: 100%;
}
</style>
