import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '../views/home/index.vue'
import register from '../views/register/index.vue'

Vue.use(VueRouter)

const routes = [
    {
    path: '/',
    redirect: "/home",
    title:'LIMS'
  },
  {
    path: '/home',
    name: 'home',
    component: home
  },
  {
    path: '/register',
    name: 'register',
    component: register,
    title:'注册'
  }
]

const router = new VueRouter({
 mode: 'history',
  //mode:'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
